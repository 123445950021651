@import '@/_styles/import';
.TravelPartyRefinement {
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;

  &__heading {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    color: $black;
  }

  &__icon .Icon {
    color: $gray-410 !important;
  }

  &__imgContents {
    text-align: center;

    &:not(.-newFilter) {
      margin-bottom: rem(24);
    }

    &.-newFilter {
      @include media-breakpoint-down(md) {
        margin-top: 30px;
        margin-bottom: 16px;
      }
    }
  }

  &__renderSailorButtons {
    margin: 30px 0 50px;
    text-align: center;
  }

  &__cabins {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 60%;
    margin: 0 auto 30px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    .cabins__count {
      font-size: 17px;
      font-weight: 400;
      line-height: 25px;
      color: $gray-510;
    }
  }

  &__sailorBtn {
    width: rem(47);
    height: rem(47);
    margin-right: rem(30);
    padding-left: rem(18);

    color: $black;

    background-color: transparent;
    border: 1px solid transparent !important;
    border-radius: 50%;

    &:last-child {
      margin-right: rem(0);
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    &:not(:disabled, .disabled):active:focus,
    &:not(:disabled, .disabled).active:focus,
    &:active {
      @include font($color: $black, $weight: bold);

      background: transparent;
      border: 2px solid $tropicalblue !important;
    }

    &:hover {
      @include font($color: $black);

      background: transparent;
      border: 1px solid $iron;
    }

    &:focus::before,
    &.focus::before {
      border: 0 !important;
    }

    &.mnvvSailorBtnDisabled {
      color: $btn-base-color-disabled;
      opacity: 0.65;
      background-color: $black_25;
      border: 1px solid $black-008;
    }
  }

  &__sailorBtn__version2 {
    width: 47px;
    height: 47px;
    margin-right: 16px;
    padding-left: rem(0);

    color: $black !important;

    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 50% !important;

    @include media-breakpoint-down(md) {
      margin-right: 40px;
    }

    &:last-child {
      margin-right: rem(0);
    }

    &:not(:disabled, .disabled):active,
    &:not(:disabled, .disabled).active,
    &:active {
      padding-left: rem(0);
      font-weight: bold;
      border: 2px solid $tropicalblue !important;
    }

    &:focus::before,
    &.focus::before {
      border: 0 !important;
    }

    &.mnvvSailorBtnDisabled {
      color: $btn-base-color-disabled;
      opacity: 0.65;
      background-color: $black_25;
      border: 1px solid $black-008;
    }
  }

  .sailorBtnCentred {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sailorCentred {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Popover {
    &__container {
      padding: rem(20) rem(25) rem(22);

      @include media-breakpoint-up(lg) {
        min-width: rem(224);
        padding: rem(30) rem(22) rem(24);
      }
    }

    &__content {
      justify-content: flex-start;
      width: 100%;

      @include media-breakpoint-up(lg) {
        flex-flow: row wrap;
        align-content: flex-start;
      }
    }
  }
}

.FilterPopover__applyButton {
  .RefinementButtons {
    .btn.TravelPartyRefinementBtn {
      width: 100%;
      margin-left: 0;

      @include media-breakpoint-down(md) {
        margin-top: 50px;
      }
    }
  }
}

#travel-party {
  .Popover__content {
    @media (max-width: $container-md-width) {
      height: 100%;
    }
  }
}
